import LogoSVG from 'assets/svgs/logo.inline.svg'
import AcceptSVG from 'assets/svgs/accept.inline.svg'
import CheckboxSVG from 'assets/svgs/checkbox.inline.svg'
import MenuSVG from 'assets/svgs/menu.inline.svg'
import PencilSVG from 'assets/svgs/pencil.inline.svg'
import SearchSVG from 'assets/svgs/search.inline.svg'
import ArrowLeftSVG from 'assets/svgs/arrowLeft.inline.svg'
import ArrowRightSVG from 'assets/svgs/arrowRight.inline.svg'
import CaretRightSVG from 'assets/svgs/caret-right.inline.svg'
import LocationPinSVG from 'assets/svgs/location-pin.inline.svg'
import CloseSVG from 'assets/svgs/close.inline.svg'
import ArrowFlairSVG from 'assets/svgs/arrow-flair.inline.svg'
import JobLaunchLogoSVG from 'assets/svgs/job-launch-logo.inline.svg'
import CardsSVG from 'assets/svgs/cards.inline.svg'
import GetToCollegeSVG from 'assets/svgs/journeys-getCollege.inline.svg'
import GetAJobSVG from 'assets/svgs/journeys-job.inline.svg'
import SucceedAtCollegeSVG from 'assets/svgs/journeys-successCollege.inline.svg'
import FacebookSVG from 'assets/svgs/facebook.inline.svg'
import FeaturedSvg from 'assets/svgs/featured.inline.svg'
import FeaturedSectionSvg from 'assets/svgs/featured-section.inline.svg'
import TikTokSVG from 'assets/svgs/tiktok.inline.svg'
import YouTubeSVG from 'assets/svgs/youtube.inline.svg'
import YoutubeBlankSVG from 'assets/svgs/youtube_blank.inline.svg'
import InstagramSVG from 'assets/svgs/instagram.inline.svg'
import TwitterSVG from 'assets/svgs/twitter.inline.svg'
import GoogleSsoSVG from 'assets/svgs/google-sso.inline.svg'
import HomeCheck1SVG from 'assets/svgs/check_1.inline.svg'
import HomeCheck2SVG from 'assets/svgs/check_2.inline.svg'
import HomeCheck3SVG from 'assets/svgs/check_3.inline.svg'
import HomeCheck4SVG from 'assets/svgs/check_4.inline.svg'
import SidebarCircle1SVG from 'assets/svgs/sidebar_circle01.inline.svg'
import SidebarCircle2SVG from 'assets/svgs/sidebar_circle02.inline.svg'
import SidebarCircle3SVG from 'assets/svgs/sidebar_circle03.inline.svg'
import EssaySVG from 'assets/svgs/essay.inline.svg'
import CollegeSVG from 'assets/svgs/college.inline.svg'
import HsSVG from 'assets/svgs/hs.inline.svg'
import DollarSVG from 'assets/svgs/dollar.inline.svg'
import ClockSVG from 'assets/svgs/clock.inline.svg'
import HeartHollowSVG from 'assets/svgs/heart-hollow.inline.svg'
import HeartFilledSVG from 'assets/svgs/heart-filled.inline.svg'
import RightArrowSVG from 'assets/svgs/right-arrow.inline.svg'
import GetSchooledSVG from 'assets/svgs/get-schooled.inline.svg'
import BoxSVG from 'assets/svgs/Box.inline.svg'
import FilledIndicatorSVG from 'assets/svgs/filled-indicator.inline.svg'
import HollowIndicatorSVG from 'assets/svgs/hollow-indicator.inline.svg'
import SmallArrowSVG from 'assets/svgs/small-arrow.inline.svg'
import CrossSVG from 'assets/svgs/cross.inline.svg'
import TickSVG from 'assets/svgs/tick.inline.svg'
import StarSVG from 'assets/svgs/star.inline.svg'
import PlaySVG from 'assets/svgs/play.inline.svg'
import ArticleSVG from 'assets/svgs/article.inline.svg'
import shouldForwardProp from '@styled-system/should-forward-prop'
import NounInterviewSVG from 'assets/svgs/noun-interview.inline.svg'
import NoResumeSVG from 'assets/svgs/no-resume.inline.svg'
import GroupSVG from 'assets/svgs/group.inline.svg'
import DownloadSVG from 'assets/svgs/vector.inline.svg'
import {
  ColorProps,
  HeightProps,
  WidthProps,
  SpaceProps,
  height,
  width,
  color,
  compose,
  space,
} from 'styled-system'
import {CSSObject} from '@emotion/react'
import styled from '@emotion/styled'

import {Theme} from 'src/styles/theme'

interface HoverColor {
  hoverColor?: keyof Theme['colors']
}

export interface Props
  extends ColorProps,
    HeightProps,
    WidthProps,
    HoverColor,
    SpaceProps {}

const hoverColor = (props: HoverColor) => {
  if (!props.hoverColor) {
    return
  }

  return {
    '&:hover': {
      ...color({...props, color: props.hoverColor}),
    },
  }
}

const svgStyles = compose(color, height, width, hoverColor, space)

interface IconOptions {
  defaultColor?: keyof Theme['colors']
  overrides?: CSSObject
}

const createIconComponent = (
  svg: React.FunctionComponent<
    Omit<React.SVGAttributes<SVGElement>, keyof Props>
  >,
  {defaultColor, overrides = {}}: IconOptions = {}
) =>
  styled(svg, {shouldForwardProp})<Props>(
    (props) => ({
      color: defaultColor ? props.theme.colors[defaultColor] : undefined,
      flexShrink: 0,
    }),
    svgStyles,
    hoverColor,
    overrides
  )

export const PencilIcon = createIconComponent(PencilSVG, {
  defaultColor: 'grey2',
})

export const Logo = createIconComponent(LogoSVG, {
  overrides: {
    fill: 'currentColor',
  },
  defaultColor: 'primary',
})

Logo.defaultProps = {
  'aria-labelledby': 'logo-title',
  role: 'img',
}

export const AcceptIcon = createIconComponent(AcceptSVG)
export const CheckboxIcon = createIconComponent(CheckboxSVG)
export const MenuIcon = createIconComponent(MenuSVG)
export const SearchIcon = createIconComponent(SearchSVG)
export const ArrowIconToRight = createIconComponent(ArrowRightSVG)
export const ArrowIconToLeft = createIconComponent(ArrowLeftSVG)
export const CaretRightIcon = createIconComponent(CaretRightSVG, {
  defaultColor: 'primary',
})

export const CaretLeftIcon = createIconComponent(CaretRightSVG, {
  defaultColor: 'primary',
  overrides: {
    transform: 'rotate(180deg)',
  },
})

export const CaretDownIcon = createIconComponent(CaretRightSVG, {
  defaultColor: 'primary',
  overrides: {
    transform: 'rotate(90deg)',
  },
})

export const LocationPinIcon = createIconComponent(LocationPinSVG, {
  defaultColor: 'grey5',
})

export const CloseIcon = createIconComponent(CloseSVG, {
  defaultColor: 'grey5',
})

export const JobLaunchArrowIcon = createIconComponent(ArrowFlairSVG, {
  defaultColor: 'jobLaunchYellow',
})

export const JobLaunchLogoIcon = createIconComponent(JobLaunchLogoSVG, {})

export const GetToCollegeIcon = createIconComponent(GetToCollegeSVG, {
  defaultColor: 'primary',
})
export const GetAJobIcon = createIconComponent(GetAJobSVG, {
  defaultColor: 'primary',
})
export const SucceedAtCollegeIcon = createIconComponent(SucceedAtCollegeSVG, {
  defaultColor: 'primary',
})

export const FacebookIcon = createIconComponent(FacebookSVG, {
  defaultColor: 'grey5',
})
export const GoogleSso = createIconComponent(GoogleSsoSVG)
export const TikTokIcon = createIconComponent(TikTokSVG, {
  defaultColor: 'grey5',
})
export const YouTubeIcon = createIconComponent(YouTubeSVG, {
  defaultColor: 'grey5',
})
export const YoutubeBlankIcon = createIconComponent(YoutubeBlankSVG, {
  defaultColor: 'grey5',
})
export const InstagramIcon = createIconComponent(InstagramSVG, {
  defaultColor: 'grey5',
})
export const TwitterIcon = createIconComponent(TwitterSVG, {
  defaultColor: 'grey5',
})

export const CardsIcon = createIconComponent(CardsSVG, {
  defaultColor: 'grey5',
})

export const FeaturedIcon = createIconComponent(FeaturedSvg, {
  defaultColor: 'green',
})
export const FeaturedSectionIcon = createIconComponent(FeaturedSectionSvg, {
  defaultColor: 'primary',
})

export const HomeCheckIcon1 = createIconComponent(HomeCheck1SVG)
export const HomeCheckIcon2 = createIconComponent(HomeCheck2SVG)
export const HomeCheckIcon3 = createIconComponent(HomeCheck3SVG)
export const HomeCheckIcon4 = createIconComponent(HomeCheck4SVG)

export const SidebarCircleIcon1 = createIconComponent(SidebarCircle1SVG, {
  defaultColor: 'grey6',
})
export const SidebarCircleIcon2 = createIconComponent(SidebarCircle2SVG, {
  defaultColor: 'grey6',
})
export const SidebarCircleIcon3 = createIconComponent(SidebarCircle3SVG, {
  defaultColor: 'grey6',
})

export const NounInterviewIcon = createIconComponent(NounInterviewSVG, {
  overrides: {
    transform: 'translateY(7px)',
  },
})
export const NoResumeIcon = createIconComponent(NoResumeSVG, {
  overrides: {
    transform: 'translateY(7px)',
  },
})

export const GroupIcon = createIconComponent(GroupSVG, {
  overrides: {
    transform: 'tranlateY(6px)',
  },
})

export const EssayIcon = createIconComponent(EssaySVG)
export const CollegeIcon = createIconComponent(CollegeSVG)
export const HsIcon = createIconComponent(HsSVG)
export const DollarIcon = createIconComponent(DollarSVG)
export const ClockIcon = createIconComponent(ClockSVG)
export const HeartHollowIcon = createIconComponent(HeartHollowSVG)
export const HeartFilledIcon = createIconComponent(HeartFilledSVG)
export const RightArrowIcon = createIconComponent(RightArrowSVG)
export const GetSchooledIcon = createIconComponent(GetSchooledSVG)
export const BoxIcon = createIconComponent(BoxSVG)
export const FilledIndicatorIcon = createIconComponent(FilledIndicatorSVG)
export const HollowIndicatorIcon = createIconComponent(HollowIndicatorSVG)
export const SmallArrowIcon = createIconComponent(SmallArrowSVG)
export const CrossIcon = createIconComponent(CrossSVG)
export const StarIcon = createIconComponent(StarSVG)
export const TickIcon = createIconComponent(TickSVG)
export const PlayIcon = createIconComponent(PlaySVG)
export const ArticleIcon = createIconComponent(ArticleSVG)
export const DownloadIcon = createIconComponent(DownloadSVG)

CloseIcon.defaultProps = {
  'aria-label': 'Close',
}
