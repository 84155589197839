import {useRef} from 'react'
import {useClickAway, useLocation} from 'react-use'

import Drawer from 'src/components/Drawer'
import {useNavDrawer, useNavDrawerAction} from 'src/context/NavDrawerContext'
import useViewer from 'src/hooks/useViewer'
import {Action, ActionLink} from 'src/components/Action'
import {Grid, Flex} from 'src/components/Box'
import {Body, ExtraSmallBody} from 'src/components/text'
import {SquiggleDivider} from 'src/components/dividers'
import {localPaths} from 'src/urls'
import useCoreJourneys from 'src/hooks/useCoreJourneys'
import JourneyIcon from 'src/components/JourneyIcon'

const NavDrawer = () => {
  const coreJourneys = useCoreJourneys()
  const drawerRef = useRef<HTMLDivElement | null>(null)
  const {isOpen} = useNavDrawer()
  const {closeDrawer} = useNavDrawerAction()
  const {viewer, signOut} = useViewer()
  const location = useLocation()

  useClickAway(drawerRef, (e) => {
    // second part is a hacky little fix for a bug introduced with the react-use upgrade
    if (!isOpen || (e.target as Element).closest('[data-no-click-away]')) {
      return
    }

    closeDrawer()
  })

  const handleSignOut = () =>
    signOut().then(() => {
      closeDrawer()
    })

  return (
    <Drawer data-cy="nav-drawer" ref={drawerRef} isOpen={isOpen}>
      <Grid p="5" as="nav" gridGap="2">
        {viewer === 'not-logged-in' ? (
          <>
            <ActionLink
              onClick={closeDrawer}
              to={localPaths.signUp}
              state={{returnTo: location.pathname}}
            >
              Sign Up
            </ActionLink>
            <ActionLink
              onClick={closeDrawer}
              to={localPaths.login}
              state={{returnTo: location.pathname}}
            >
              Log In
            </ActionLink>
          </>
        ) : (
          viewer && (
            <Flex justifyContent="space-between">
              <ActionLink
                onClick={closeDrawer}
                to={localPaths.profile}
                state={{returnTo: location.pathname}}
              >
                My Profile
              </ActionLink>
              <Action onClick={handleSignOut}>
                <Body color="grey5">Log Out</Body>
              </Action>
            </Flex>
          )
        )}
        <SquiggleDivider />

        <ExtraSmallBody color="grey5">Pursue Your Goal</ExtraSmallBody>
        {coreJourneys.map((journey) => (
          <ActionLink
            key={journey.id}
            onClick={closeDrawer}
            to={journey.permalink}
          >
            <Flex alignItems="center">
              {journey.icon && <JourneyIcon icon={journey.icon} mr="1" />}
              <span>{journey.name}</span>
            </Flex>
          </ActionLink>
        ))}
        <SquiggleDivider />

        <ExtraSmallBody color="grey5">Resources</ExtraSmallBody>
        <ActionLink onClick={closeDrawer} to={localPaths.sendText}>
          Text an Advisor
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.scholarships}>
          Find Scholarships
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.collegeAppHelp}>
          Review my College App Essay
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.resumeBuilder}>
          Build my Resume
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.resumeHelp}>
          Review my Resume
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.interviewCoach}>
          Practice a Job Interview
        </ActionLink>
        <SquiggleDivider />

        <ExtraSmallBody color="grey5">Educators & Partners</ExtraSmallBody>
        <ActionLink onClick={closeDrawer} to={localPaths.educatorResources}>
          Resources for Educators
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.partner}>
          Partner with Us
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.educatorContent}>
          Educator Content Library
        </ActionLink>
        <ActionLink onClick={closeDrawer} to={localPaths.aboutUs}>
          About Get Schooled
        </ActionLink>
      </Grid>
    </Drawer>
  )
}

export default NavDrawer
